import { AppConfig } from "./../../app/app.config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';

/*
  Generated class for the DeviceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class DeviceProvider {
  private UUID: any;
  private ID: any;
  private name: string;
  private version: string;
  private store: string;
  private code: string;
  private connection: any;
  private storeName = "FarmaciaIbanez";
  constructor(public http: HttpClient) {
    console.log("Hello DeviceProvider Provider");
    // this.ID = uuidv4(this.storeName);
    // console.log(this.ID);
  }

  setID(id: string) {
    this.ID = id;
  }

  setUUID(uuid: any) {
    this.UUID = uuid;
  }

  setName(name: string) {
    this.name = name;
  }

  setVersion(version: string) {
    this.version = version;
  }

  load(uuid: string) {
    console.log("Loading device " + uuid);
  }

  getInfo() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(AppConfig.API_URL + "/device/info/" + this.ID)
        .toPromise()
        .then((res: any) => {
          this.name = res.name;
          this.code = res.code;
          this.store = res.store;
          this.ID = res._id;
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }

  searchByUUID(uuid: string) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(
          AppConfig.API_URL + "/search-device/by-uuid/" + (uuid || this.UUID)
        )
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }
  searchByUUIDBRD(uuid: string) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(
          AppConfig.BRD_URL + "/search-device/by-uuid/" + (uuid || this.UUID)
        )
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }

  register() {
    let promise = new Promise((resolve, reject) => {
      if (!this.UUID) {
        console.log("UUID not found for device.");
        reject({ error: "UUID not found" });
        return;
      }
      if (!this.name) {
        console.log("Name not found for device.");
        reject({ error: "Name not found" });
        return;
      }
      console.log("Registering at: " + AppConfig.API_URL);
      this.http
        .put(AppConfig.API_URL + "/register-device", {
          uuid: this.UUID,
          name: this.name,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });

    return promise;
  }
  registerBrd() {
    let promise = new Promise((resolve, reject) => {
      if (!this.UUID) {
        console.log("UUID not found for device.");
        reject({ error: "UUID not found" });
        return;
      }
      if (!this.name) {
        console.log("Name not found for device.");
        reject({ error: "Name not found" });
        return;
      }
      console.log("Registering at: " + AppConfig.BRD_URL);
      this.http
        .put(AppConfig.BRD_URL + "/register-device", {
          uuid: this.UUID,
          name: this.name,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });

    return promise;
  }

  getUUID() {
    return this.UUID;
  }

  heartbeat(version: string) {
    let promise = new Promise((resolve, reject) => {
      if (!this.UUID) {
        console.log("UUID not found for device.");
        reject({ error: "UUID not found" });
        return;
      }

      this.http
        .post(
          AppConfig.API_URL + "/device-heartbeat/" + this.UUID + "/" + version,
          {}
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            resolve({});
          }
        );
    });

    return promise;
  }
}
