export class AppConfig {
  public static API_URL = "https://api.farmashopper.360bvm.net";
  public static BRD_URL = "https://api.brands.360bvm.net";
  public static deviceWakeupHour = 9;
  public static deviceGoBlackHour = 22;
  public static npsVersion = "0001";
  public static voucherAmount = "5";
  public static clientURL = "https://farmashopper.360cvm.net";
  public static defaultLang = "es";
}
