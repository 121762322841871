import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { DeviceProvider } from '../providers/device/device';
import { BqPollProvider } from '../providers/bq-poll/bq-poll';
import { BqDataStorageProvider } from '../providers/bq-data-storage/bq-data-storage';
import { HttpClientModule } from '@angular/common/http';
import { PoliticaPageModule } from '../app/politica/politica.module'
import { IonicStorageModule } from '@ionic/storage';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
@NgModule({ 
  declarations: [AppComponent],
  entryComponents: [  ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule , HttpClientModule , IonicStorageModule.forRoot(
    {
      name: '__satisvalue',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }
  )],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppConfig,
    UniqueDeviceID,
    DeviceProvider,
    BqDataStorageProvider,
    VideoPlayer,
    BqPollProvider,
    Autostart,
    AndroidFullScreen
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
