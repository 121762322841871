import { AppConfig } from './../../app/app.config';
import { sha256 } from 'js-sha256';
import { HttpClient } from '@angular/common/http';
import {  HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { File } from '@ionic-native/file';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { BqDataStorageProvider } from '../bq-data-storage/bq-data-storage';
import { DeviceProvider } from '../device/device';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
@Injectable()
export class BqPollProvider {
    private _pollID: string;
    private _baseDir: string;
    private _currentStep: number;
    private _prevStep: number;
    private _answers: any;
    private _stepFlow: any;
    private _finished: boolean;
    private _email: string;
    headers = new HttpHeaders({
        'bdt' : '4a575dfc-2e48-4411-82d6-c3e61baa205f' ,
    });
    constructor(
        public http: HttpClient,
        private router: Router,
        private _bqStorage: BqDataStorageProvider,
        private _device: DeviceProvider,
        private storage: Storage,
        public alertController: AlertController
    ) {
        this._baseDir = 'surveys';
        this._currentStep = -1;
        this._prevStep = -1;
        this._answers = [];
        this._stepFlow = [];
        this._email = '';
        this._finished = false;
        // : this._device.getUUID()s
        let x = this._device.getUUID();
        console.log(x);
        // this.headers.append()
    }

    private log(text: string) {
        console.log('[SURVEY] ' + text);
    }

    private generateID() {
        console.log("Generate ID");
        this.storage.get('CurrentSurvey').then(res=>{
            console.log(res);
            this.storage.remove(res);
        })
        var promise = new Promise((resolve, reject) => {
            let rID =
            'BQHP-' +
            AppConfig.npsVersion +
            '-' +
            'web' +
            '-' +
            moment().unix();
            resolve(rID);
        });

        return promise;
    }

    // private checkDirectory() {
    //     var promise = new Promise((resolve, reject) => {
    //         this._file
    //             .checkDir(this._file.externalDataDirectory, this._baseDir)
    //             .then(dir => {
    //                 resolve(true);
    //             })
    //             .catch(dirErr => {
    //                 if (dirErr.code === 1) {
    //                     this._file
    //                         .createDir(
    //                             this._file.externalDataDirectory,
    //                             this._baseDir,
    //                             false,
    //                         )
    //                         .then(dirRes => {
    //                             resolve(true);
    //                         })
    //                         .catch(ndirErr => {
    //                             reject(ndirErr);
    //                         });
    //                 } else {
    //                     reject(dirErr);
    //                 }
    //             });
    //     });

    //     return promise;
    // }

    public checkOnline() {
        var promise = new Promise((resolve, reject) => {
            this.http
                .get(AppConfig.API_URL + '/alive')
                .toPromise()
                .then(r => {
                    resolve(r);
                })
                .catch(e => {
                    reject(e);
                });
        });

        return promise;
    }

    getID() {
        return this._pollID;
    }

    toObject() {
        let rObj = {
            code: this._pollID,
            steps: this._answers,
            flow: this._stepFlow,
            email: this._email,
            finished: this._finished,
        };

        console.dir(rObj);

        return rObj;
    }

    dumpInfo() {
        this.log('[DEBUG INFO]');
        this.log('Current pollID  : ' + this._pollID);
        this.log('Current EMAIL   : ' + this._email);
        this.log('Step flow       : ' + this._stepFlow);
        this.log('Current step    : ' + this._currentStep);
        this.log('Previous step   : ' + this._prevStep);
        this.log('Current answers : ' + this._answers.length);
        for (let answer of this._answers) {
            this.log(
                'Answer step     : ' +
                    answer.step +
                    ' 🠆 ' +
                    JSON.stringify(answer.answer),
            );
        }
        this.log('[DEBUG INFO END]');
    }

    checkCurrentSurvey(repeat: boolean) {   
        let cSurvey = this._bqStorage.get('CurrentSurvey');
        if (!repeat) {
            this.log('Current survey: ' + cSurvey);
        } else {
            setTimeout(() => {
                this.log('Current survey: ' + cSurvey);
                this.checkCurrentSurvey(true);
            }, 5 * 1000);
        }
    }

    generateNew() {
        
        this.generateID().then((pollID: string) => {
            this._pollID = pollID;
            console.log('[[NEW POLL]] Current pollID: ' + this._pollID);
            setTimeout( async () => {
                const alert = await this.alertController.create({
                    // cssClass: 'my-custom-class',
                    header: 'Lo sentimos',
                    message: 'El tiempo de encuesta se ha agotado.',
                    buttons: ['OK']
                  });
                  await alert.present();
                  
                  setTimeout(() =>{
                    console.log('Cerrar')
                    alert.dismiss();
                    this.storage.remove(this._pollID);
                    this.router.navigate(['/welcome']);
                  } , 1000)
            }, 180000);
            this._currentStep = -1;
            this._prevStep = -1;
            this._answers = [];
            this._stepFlow = [];
            this._email = '';
            this._finished = false;

            this._bqStorage.set('CurrentSurvey', this._pollID);
            this._currentStep = 0;
        });
    }

    async update() {
        var promise = new Promise( async (resolve, reject) => {
            this.log('Updating poll savefile');
            this.dumpInfo();
            console.log(this._pollID);
            console.log(this._currentStep)
            console.log(this._answers);
            this.storage.get(this._pollID).then( async res => {
                console.log(res);
                if(res == null){
                    console.log('setear ');
                    for(let answer of this._answers){
                        if(answer.step == this._currentStep){
                            this.storage.set(this._pollID , [answer]);
                        }
                    }
                    resolve({});
                }else{
                    let actual  = await this.validDataActual(res);
                    // console.log(actual);
                    let valueNow =  await this.validAnswer();    
                    actual.push(valueNow); 
                    console.log(actual);
                    // this.storage.clear();
                    resolve({});
                 }
            }).catch(err =>{
                console.log(err);
                reject({});
            })
           
        });
        return promise;
    }
    async validDataActual(actual){
        await actual.forEach((item, index) => {
           if(item.step == this._currentStep){
            actual.splice(index,1);
            console.log(actual);
           }
        });
        console.log(actual);
        return actual;
    }
    async validAnswer(){
        let data;
         for(let answer of this._answers){
            if(answer.step == this._currentStep){
                // console.log(answer)
                data = answer;
            }
        }
        console.log(data);
        return data;
    }
    refresh() {
        this.log('Loading poll from savefile');
    }

    uploadSurvey(survey: any, unfinished: boolean) {
        let extraParams = '';

        if (unfinished === true) {
            extraParams = '?n=1';
        }
        console.log(survey , unfinished);
    //    console.log()
        var promise = new Promise((resolve, reject) => {
            this.http
                .post(AppConfig.API_URL + '/add-survey' + extraParams, {
                    suid: survey.code,
                    email: survey.email,
                    survey: survey,
                    deviceUUID: this._device.getUUID()
                } , {headers: this.headers}
                )
                .toPromise()
                .then(cRes => {
                    resolve(cRes);
                })
                .catch(err => {
                    console.log(
                        'ERROR Uploading survey: ' + JSON.stringify(err),
                    );
                    reject(err);
                });
        });

        return promise;
    }

    saveSurvey( email? ) {
        var promise = new Promise((resolve, reject) => {
            this._email = email;
            this._finished = true;
                console.log("guardar")
                console.log(this.headers);
                this.http
                    .post(AppConfig.API_URL + "/add-survey", {
                        suid: this._pollID,
                        email: email,
                        survey: this.toObject(),
                        deviceUUID: '4a575dfc-2e48-4411-82d6-c3e61baa205f'
                    } ,  {headers: this.headers} )
                    .toPromise()    
                    .then(async (cRes) => {
                        resolve(cRes);
                        console.log("resuelto y guardar marca , marcar cookie para no permitir reenvio");
                        var date = new Date();
                        date.setDate(date.getDate() + 7);
                        console.log(date);
                        console.log(date.toUTCString());
                        document.cookie = "limit=true; expires="+date.toUTCString()+"; path=/";
                        console.log(this._pollID);
                        // document.cookie = "nombrecookie=valorcookie; max-age=3600; path=/";
                        // await this.saveBrd(email).then( res =>{

                        // })
                        
                    })
                    .catch(err => {
                        console.log('Error' , err);
                        reject(err);
                        
                    });
        });

        return promise;
    }
    async saveBrd(email: string) {
        var promise = new Promise((resolve, reject) => {
            this._email = email;
            this._finished = true;

             console.log('Agregar a marcas')
                  
                this.http
                    .post(AppConfig.BRD_URL + "/add-survey", {
                        suid: this._pollID,
                        email: email,
                        survey: this.toObject(),
                        deviceUUID: this._device.getUUID()
                    })
                    .toPromise()
                    .then(cRes => {
                        console.log('Then' , JSON.stringify(cRes));
                        this.storage.remove(this._pollID);
                        resolve(cRes);
                    })
                    .catch(err => {
                        console.log("err" , JSON.stringify(err))
                        reject(err);
                    });
        });

        return promise;
    }

    updateStep(step: number, value: any) {
        var promise = new Promise((resolve, reject) => {
            console.log(
                'Updating step ' +
                    this._pollID +
                    ': From ' +
                    this._currentStep +
                    ' -> ' +
                    step,
            );
            this._stepFlow.push(this._currentStep);
            this._prevStep = this._currentStep;
            this._currentStep = step;
            this._answers.push({
                step: step,
                answer: value,
                timestamp: Date.now(),
            });

            this.update()
                .then(uRes => resolve({}))
                .catch(uErr => reject(uErr));
        });

        return promise;
    }

    getPreviousStep() {
        return this._prevStep;
    }

    getStepAnswer(step: number) {
        for (let a of this._answers) {
            if (a.step === step) return a.answer;
        }

        return null;
    }

    uploadIncomplete(survey: any) {
        var promise = new Promise((resolve, reject) => {
            this.http
                .put(AppConfig.API_URL + '/survey/incomplete', {
                    suid: survey.code || 'No code',
                    email: survey.email || 'No email',
                    survey: survey,
                })
                .toPromise()
                .then(cRes => {
                    resolve(cRes);
                })
                .catch(err => {
                    console.log(
                        'ERROR Uploading survey: ' + JSON.stringify(err),
                    );
                    reject(err);
                });
        });

        return promise;
    }
    getUniverses(){
        var promise = new Promise((resolve, reject) => {
            this.http
                .get(AppConfig.BRD_URL + '/universe')
                .toPromise()
                .then(cRes => {
                    resolve(cRes);
                })
                .catch(err => {
                    console.log(
                        'ERROR Uploading survey: ' + JSON.stringify(err),
                    );
                    reject(err);
                });
        });
        return promise;
    }
    getBrandsUniverso(id){
        let url = AppConfig.BRD_URL + '/brands/universe/'+id;
         return this.http.get(url).toPromise()
         .then(res =>{
           console.log( res );
           return res;
         })
         .catch(err =>{
           console.log( "Error " , err );
         })
    }
    cleartoStart(){
        console.log('Se acabo el tiempo de tu encuesta');
    }
}
