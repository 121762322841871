import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private autostart: Autostart,
    private androidFullScreen: AndroidFullScreen
  ) {
    this.initializeApp();
    // this.autostart.enable();
    // this.backgroundMode.isScreenOff(function(){
    //     console.log('SE APAGO PANTALLA')
    // })
    // this.backgroundMode.isActive().valueOf;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault(  );
      // this.splashScreen.hide();     
    window.localStorage.setItem("BQTToken", '4a575dfc-2e48-4411-82d6-c3e61baa205f');
    
    })    
  }
  ngOnInit() {
    // this.nav.push(FirstRunPage);
  }
  screenOff(){

  }
}
